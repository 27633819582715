import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule, ErrorHandler } from '@angular/core';
import { AppComponent } from './app.component';
import { CoreModule } from '@app/modules/core/core.module';
import { SharedModule } from '@app/modules/shared/shared.module';
import { AppRoutingModule } from '@app/app-routing.module';
import { ToastrModule } from 'ngx-toastr';
import { DragulaModule } from 'ng2-dragula';
import { TreeModule } from '@circlon/angular-tree-component';
import { RegistrationPageComponent } from '@app/pages/registration/registration-page.component';
import { LoginPageComponent } from '@app/pages/login/login-page.component';
import { httpInterceptorProviders } from '@app/interceptors';
import { AppConfig } from '@app/app.config';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { OnlyOneErrorPipe } from '@app/pages/reset-password/only-one-error.pipe';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions } from '@angular/material/tooltip';
import { FeatureFlagService } from '@app/modules/shared/feature-flag/feature-flag.service';
import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';
export function initializeApp(appConfig: AppConfig) {
    return () => appConfig.load();
}

export const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
    showDelay: 0,
    hideDelay: 0,
    touchendHideDelay: 0,
    disableTooltipInteractivity: true,
};

@NgModule({
    declarations: [
        AppComponent,
        RegistrationPageComponent,
        LoginPageComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent,
        OnlyOneErrorPipe,
    ],
    imports: [
        BrowserModule,
        CoreModule,
        SharedModule,
        AppRoutingModule,
        ToastrModule.forRoot({
            positionClass: 'toast-bottom-right',
            timeOut: 0,
            extendedTimeOut: 0,
            closeButton: true,
        }),
        DragulaModule.forRoot(),
        TreeModule,
    ],
    providers: [
        httpInterceptorProviders,
        AppConfig,
        FeatureFlagService,
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            deps: [AppConfig, Sentry.TraceService],
            multi: true,
        },
        {
            provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
            useValue: myCustomTooltipDefaults,
        },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
