import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CdkTableModule } from '@angular/cdk/table';
import { NgxSpinnerModule } from 'ngx-spinner';
import { TranslationsModule } from '@app/modules/shared/translations/translations.module';
import { MatSliderModule } from '@angular/material/slider';
import { FeatureFlagDirective } from '@app/modules/shared/feature-flag/feature-flag.directive';

@NgModule({
    imports: [],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatTabsModule,
        MatCardModule,
        MatButtonModule,
        MatBadgeModule,
        MatIconModule,
        MatTableModule,
        MatDividerModule,
        MatAutocompleteModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatCheckboxModule,
        MatSortModule,
        MatDialogModule,
        MatProgressBarModule,
        MatTooltipModule,
        MatMenuModule,
        MatToolbarModule,
        MatMenuModule,
        MatSidenavModule,
        MatSlideToggleModule,
        NgxSpinnerModule,
        CdkTableModule,
        MatProgressSpinnerModule,
        TranslationsModule,
        MatSliderModule,
        FeatureFlagDirective,
    ],

    declarations: [FeatureFlagDirective],
    providers: [],
})
export class SharedModule {}
