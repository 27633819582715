import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {
    createPasswordMatchValidator,
    createPasswordStrengthValidator
} from "@app/modules/dashboard/directives/password-strength.validator";
import {ActivatedRoute, Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {UserService} from "@app/modules/core/services/user.service";
import {SUCCESS_TOASTER_OPTIONS} from "@app/modules/shared/code-settings/project-settings";

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
    resetForm = this.fb.group({
        user: [''],
        password: ['', [Validators.required,
            createPasswordStrengthValidator()]],
        confirm_password: ['', [Validators.required]]
    }, {
        validator: createPasswordMatchValidator('password', 'confirm_password')
    });

    get user() {
        return this.resetForm.controls['user'];
    }

    get password() {
        return this.resetForm.controls['password'];
    }

    get confirm_password() {
        return this.resetForm.controls['confirm_password'];
    }

    isReadOnly: boolean = true;
    userData: string;

    constructor(private fb: FormBuilder,
                private router: Router,
                private toastr: ToastrService,
                private userService: UserService,
                private route: ActivatedRoute) {}

    ngOnInit() {
        this.route.queryParams.subscribe(params => {
                const currUser = params.id;
                this.userService.getAllUsers().subscribe(users => {
                    if (!users) {
                        return;
                    }
                    const userEmail = users.find(user => user['Id'] == currUser)?.email
                    this.resetForm.controls['user'].patchValue(userEmail ? userEmail : '');
                })
            }
        );
    }

    submit() {
        this.userService.updateUserPassword(this.resetForm.value).subscribe(
            data => {
                if(data){
                    this.toastr.success('Your password was successfully changed.', '', SUCCESS_TOASTER_OPTIONS)
                    this.router.navigate(['login'], {state: {resetPassword: true}});
                }
            },
            err => {
                this.resetForm.controls['confirm_password'].setErrors({
                    ...this.resetForm.controls['confirm_password'].errors,
                    invalid: true
                });
            }
        );
    }

    goToLoginPage() {
        this.router.navigate(['login']);
    }


    showPassword(passwordInput: HTMLInputElement): string {
        return passwordInput.type = passwordInput.type == 'password' ? 'text' : 'password';
    }

    setAttribute(value?: boolean) {
        this.isReadOnly = value;
    }

    removeAttribute(value?: boolean) {
        this.isReadOnly = value;
    }

    checkInput(confirmInput: HTMLInputElement) {
        if (confirmInput?.value == '') {
            this.isReadOnly = true;
        }
    }
}
