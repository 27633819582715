import {ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnInit} from '@angular/core';
import {UserService} from '@app/modules/core/services/user.service';
import {Router} from '@angular/router';
import {FormBuilder, Validators} from '@angular/forms';
import {NgxSpinnerService} from 'ngx-spinner';
import * as CryptoJS from 'crypto-js';
import {map, startWith} from "rxjs/operators";
import {LoginData} from "@app/modules/core/interfaces/user";
import {Observable} from "rxjs";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";

@Component({
    templateUrl: 'login-page.component.html',
    styleUrls: ['login-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class LoginPageComponent implements OnInit {

    loading = false;
    isToRememberUser: boolean = true;
    filteredUsers: Observable<string[]>;
    usersFromStorage: any[];
    src:SafeResourceUrl;

    loginForm = this.fb.group({
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required]],
        remember: [true]
    });

    get email() {
        return this.loginForm.controls['email'];
    }

    get password() {
        return this.loginForm.controls['password'];
    }

    get remember() {
        return this.loginForm.controls['remember'];
    }

    constructor(
        private userService: UserService,
        private spinner: NgxSpinnerService,
        private router: Router,
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
        private sanitizer: DomSanitizer
    ) {}

    ngOnInit() {
        this.usersFromStorage = this.decryptData(JSON.parse(localStorage.getItem('userData')));

        if (this.usersFromStorage?.length > 0) {
            this.loginForm.setValue(this.usersFromStorage[this.usersFromStorage.length - 1]);
        } else {
            this.resetFormData();
        }

        this.loginForm.valueChanges.subscribe(value => {
            if (value.email == '' && value.password !== '') {
                this.password.patchValue('');
            }
        })

        //to filter users by entering email to set autocomplete
        this.filteredUsers = this.loginForm.get('email').valueChanges
            .pipe(
                startWith(''),
                map(value => value.length > 0 ? this.filter(value) : [])
            )
        this.src = this.sanitizer.bypassSecurityTrustResourceUrl('https://codiq.io/news-login/');
    }

    filter(val: string): string[] {
        return this.usersFromStorage?.filter(option =>
            option.email.toLowerCase().indexOf(val.toLowerCase()) === 0);
    }

    /* to prevent showing browser save password pop up*/
    @HostListener('show', ['$event'])
    show() {
        event.preventDefault();
        return false;
    }

    submit() {
        if (this.loginForm.valid && this.remember.value == true) {
            this.saveUserData([this.loginForm.value]);
        } else if (this.usersFromStorage?.length > 0 && this.remember.value == false) {
            //to remove stored user from local storage if he changed setting to remember me = false
            const userData = JSON.parse(localStorage.getItem('userData'));
            const duplicatedInd = userData?.findIndex(user => user.email == this.email?.value);
            if (duplicatedInd != -1) {
                userData.splice(duplicatedInd, 1);
                localStorage.removeItem('userData');
                localStorage.setItem('userData', JSON.stringify(userData))
                this.usersFromStorage = this.decryptData(userData);
            }
        }

        this.userService.login({email: this.email.value, password: this.password.value}).subscribe(
            data => {
                this.spinner.show();
                this.loading = true;
                this.router.navigate(['/dashboard/entry']).then(r => this.loading = false);
            },
            err => {
                this.loginForm.controls['password'].setErrors({
                    ...this.loginForm.controls['password'].errors,
                    invalid: true
                });
                this.cdr.markForCheck();
            }
        );
    }

    goToPage(url) {
        window.location.href = url;
    }

    showPassword(passwordInput): string {
        return passwordInput.type = passwordInput.type == 'password' ? 'text' : 'password';
    }

    goToForgotPassword() {
        this.router.navigate(['forgot']);
    }

    setToRemember($event: Event) {
        this.remember.patchValue($event.target['checked']);
    }

    saveUserData(data) {
        let userArr = JSON.parse(localStorage.getItem('userData')) || [];
        const encryptedData = this.encryptData(data);
        if (userArr?.length == 0) {
            this.updateUsersData(encryptedData);
            return;
        } else {
            let duplicatedInd = userArr.findIndex(user => user.email == data[0].email);
            if (duplicatedInd != -1) {
                userArr.splice(duplicatedInd, 1);
                userArr = [...userArr, ...encryptedData];
                this.updateUsersData(userArr);
                return
            } else {
                userArr = [...userArr, ...encryptedData];
                this.updateUsersData(userArr);
            }
        }
    }

    /**
     * To encrypt password data before setting it to local storage
     * @param data - login/password fields
     * @return encrypted password value
     */
    encryptData(data) {
        const passwordData = data.password ? data.password : data[0].password;
        var words = CryptoJS.enc.Utf8.parse(passwordData);
        var base64 = CryptoJS.enc.Base64.stringify(words);
        data.password ? data.password : data[0].password = base64;
        return data;
    }

    /* setAttribute(value?: boolean) {
         this.isToRememberUser = value;
     }

     removeAttribute(value: boolean, val?) {
         console.log('val', val)
         this.isToRememberUser = value;
     }

     checkInput(confirmInput: HTMLInputElement) {
         if (confirmInput?.value == '') {
             this.isToRememberUser = true;
         }
     }*/

    setPassword(event) {
        this.password.patchValue(this.usersFromStorage?.find(user => user.email.trim() === event.trim())?.password);
        this.remember.patchValue(this.usersFromStorage?.find(user => user.email.trim() === event.trim())?.remember);
    }

    /**
     * Updating stored data with users in local storage
     * @param users - arr of users
     */
    updateUsersData(users: LoginData) {
        localStorage.removeItem('userData');
        localStorage.setItem('userData', JSON.stringify(users))
        this.usersFromStorage = this.decryptData(JSON.parse(localStorage.getItem('userData')));
    }

    resetFormData() {
        this.remember.patchValue(true);
        this.email.patchValue('');
        this.password.patchValue('');
    }

    /**
     * To return back normal password data from local storage
     * @param data - users data
     * @return user decrypted password/email/rememeber fields
     */
    decryptData(data) {
        data?.map(us => {
            us.password = CryptoJS.enc.Utf8.stringify(CryptoJS.enc.Base64.parse(us.password)) || ''
        })
        return data;
    }
}
