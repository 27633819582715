import {AbstractControl, ValidationErrors, ValidatorFn, FormGroup} from '@angular/forms';


export function createPasswordStrengthValidator(): ValidatorFn {
    return (control:AbstractControl) : ValidationErrors | null => {

        if (!control.value) {
            return null;
        }

        const minLength = control.value.length >= 8;
        const hasUpperCase = /[A-Z]+/.test(control.value);
        const hasLowerCase = /[a-z]+/.test(control.value);
        const hasNumeric = /[0-9]+/.test(control.value);
        const passwordValid = hasUpperCase && hasLowerCase && hasNumeric && minLength;

        return !passwordValid ? {passwordStrength:true}: null;
    }
}


export function createPasswordMatchValidator(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];
        if (matchingControl.errors && !matchingControl.errors.passwordMatch) {
            return;
        }
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ passwordMatch: true });
        } else {
            matchingControl.setErrors(null);
        }
    }
}
