import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { RegistrationPageComponent } from '@app/pages/registration/registration-page.component';
import { LoginPageComponent } from '@app/pages/login/login-page.component';
import { AuthGuard } from '@app/guards/auth.guard';
import { ForgotPasswordComponent } from '@app/pages/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from '@app/pages/reset-password/reset-password.component';

const routingConfiguration: ExtraOptions = {
    paramsInheritanceStrategy: 'always',
    relativeLinkResolution: 'legacy',
};

const routes: Routes = [
    { path: '', redirectTo: 'dashboard/entry', pathMatch: 'full' },
    { path: 'login', component: LoginPageComponent },
    { path: 'registration', component: RegistrationPageComponent },
    { path: 'forgot', component: ForgotPasswordComponent },
    { path: 'reset', component: ResetPasswordComponent },
    {
        path: 'dashboard',
        loadChildren: () => import('@app/modules/dashboard/dashboard.module').then(m => m.DashboardModule),
        canLoad: [AuthGuard],
        canActivate: [AuthGuard],
    },
    {
        path: 'recipes',
        loadChildren: () =>
            import('@app/modules/recipe-definition/recipe-definition.module').then(m => m.RecipeDefinitionModule),
    },
    { path: '**', redirectTo: 'dashboard/entry' },
];

@NgModule({
    declarations: [],
    imports: [RouterModule.forRoot(routes, routingConfiguration)],
    exports: [RouterModule],
    providers: [AuthGuard],
})
export class AppRoutingModule {}
