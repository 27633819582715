    <div class="wrapper">
        <div class="left">
            <div class="content-wrapper">
                <div class="logo" style="background-image: url('assets/image/logo-horizontal.svg')"></div>
                <div class="title">
                    <div class="left-side" style="background-image: url('assets/image/Vector.svg')"></div>
                    <div class="greeting-text" style="background-image: url('assets/image/fotgot_title.svg')"></div>
                    <div class="right-side" style="background-image: url('assets/image/Union.svg')"></div>
                </div>
                <div class="content">
                    <div class="form-title" style="background-image: url('assets/image/forgot__password.svg')"></div>
                    <div class="inner">
                        <div class="inner-content">
                            <span class="info">Enter the email address associated with your account</span>
                            <div class="field">
                                <span class="label">Email Address</span>
                                <div class="input">
                                    <i style="background-image: url('assets/image/icons/letter.svg')"></i>
                                    <input type="email" placeholder="Your email address"
                                           [formControl]="email">
                                </div>
                                <mat-error *ngIf="email.errors?.required && email.touched">
                                    Email is required
                                </mat-error>
                                <mat-error *ngIf="email.errors?.email">
                                    Email is invalid
                                </mat-error>
                                <mat-error *ngIf="email.errors?.invalid">
                                    <i style="background-image: url('assets/image/icons/warning.svg')"></i>
                                    <div>Email is not found. <br/>
                                       Please, try again.</div>
                                </mat-error>



                                <mat-hint class="message" *ngIf="isValidUserName && email.touched">
                                    <i style="background-image: url('assets/image/icons/checked.svg')"></i>
                                    <div>Please look for a confirmation email from CodiQ Support <br/>
                                        and follow the link inside</div>
                                </mat-hint>
                            </div>
                            <button class="button submit" (click)="sendPassword(email.value)"
                                    [disabled]="email.invalid || isValidUserName || isSent">
                                <span>Reset your password</span>
                            </button>
                            <button class="button" (click)="goToPage('https://codiq.io/contact/')">
                                <span>Need help? Contact Support</span>
                            </button>
                        </div>
                        <div class="back" (click)="goToLoginPage()">Back to <span>Login</span></div>
                    </div>
                </div>
                  <div class="copyright">© 2022 CodiQ 4.0 Ltd. All rights reserved.</div>
            </div>
        </div>
        <div class="right" style="background-image: url('assets/image/bg_forgot.png')"></div>
    </div>


