import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpInterceptor, HttpErrorResponse, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
    constructor(private router: Router, private toastr: ToastrService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError(err => {
                if (err.url && err.url.includes('getMonitorOTFromOpc')) {
                    return throwError(err.error);
                }
                if (err instanceof HttpErrorResponse) {
                    switch (err.status) {
                        case 400:
                            if (this.router?.url === '/login') return;

                            this.toastr.error(err.error.errorMessage || err.message);
                            break;
                        case 401:
                            this.router.navigate(['/login']);
                            break;
                        case 502:
                        case 503:
                            this.toastr.error(err.error);
                            break;
                        default:
                            this.parseErrorBlob(err).then(res => {
                                if (!res?.success) {
                                    if (res && res?.message) this.toastr.error(res?.message);
                                }
                            });
                            break;
                    }
                }
                return throwError(err.error);
            })
        );
    }

    parseErrorBlob(err: HttpErrorResponse): Promise<any> {
        let res = Promise.resolve(err.error);
        const reader: FileReader = new FileReader();
        if (err.error instanceof Blob) {
            res = new Promise((resolve, reject) => {
                reader.onloadend = e => {
                    resolve(JSON.parse(<string>reader.result));
                };
                reader.readAsText(err.error);
            });
        }
        return res;
    }
}
