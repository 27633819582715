import { Component, OnInit } from '@angular/core';
import {FormControl, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {UserService} from "@app/modules/core/services/user.service";
import {NgxSpinnerService} from "ngx-spinner";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
    email = new FormControl('', [Validators.required, Validators.email]);
    isValidUserName:boolean = false;
    isSent:boolean = false;

  constructor(private router: Router,
              private userService: UserService) {}

  ngOnInit() {}

    sendPassword(email: string) {
        this.isSent  = true;
        sessionStorage.removeItem('projectId');
        this.userService.getUserPassword(email).subscribe(
            data => {
                if(data){
                    this.isValidUserName = true;
                    this.email.setErrors({
                        ...this.email.errors,
                        required: false
                    })

                    this.removeNotification();
                }
            },
            err => {
                this.email.setErrors({
                    ...this.email.errors,
                    invalid: true
                });
                this.removeNotification();
            }
        );
    }

    goToPage(url: string) {
        window.location.href = url;
    }

    goToLoginPage(){
        this.router.navigate(['login']);
    }

    removeNotification(){
        setTimeout(()=>{
            this.isValidUserName = false;
            this.email.patchValue('');
            this.email.markAsUntouched();
            this.isSent = false;
        }, 4000)
    }
}

