export interface FeatureFlag {
    name: string;
    description?: string;
    isEnabled: boolean;
}

export enum FeatureFlagNames {
    verification = 'Verification',
    sentryMonitoring = 'SentryMonitoring',
    userManagement = 'UserManagement',
    batch = 'Batch',
    mainMenuInLibraryDataTypes = 'MainMenuInLibraryDataTypes',
    exportToPlcFromPlcTasks = 'ExportToPlcFromPlcTasks',
    editingExistingLibraryDataTypes = 'EditingExistingLibraryDataTypes',
    featuresOverOperatingTable = 'FeaturesOverOperatingTable',
    pathToTags = 'PathToTags',
    treeViewInMappingTagSearch = 'TreeViewInMappingTagSearch',
    showDisabledStateForGA = 'ShowDisabledStateForGA',
    zOrderLayout = 'ZorderLayout',
    enablePIDLoop = 'EnablePIDLoop',
    projectConcurrency = 'ProjectConcurrency',
}

export interface FeatureFlagData {
    featureName: string;
    featureRole?: string;
    isDisabledInComponent?: boolean;
}

export enum FeatureFlagState {
    show,
    hide,
    disabled,
}

export const FeatureFlagHide: string[] = [
    FeatureFlagNames.verification,
    FeatureFlagNames.featuresOverOperatingTable,
    FeatureFlagNames.projectConcurrency,
];
