export const SVG_TOOLBAR_ICONS = {
    account: { url: 'assets/image/icons/toolbar/account.svg', name: 'ctb_account' },
    generate_code: { url: 'assets/image/icons/toolbar/generate-code.svg', name: 'ctb_generate_code' },
    inspector: { url: 'assets/image/icons/toolbar/inspector.svg', name: 'ctb_inspector' },
    library: { url: 'assets/image/icons/toolbar/library.svg', name: 'ctb_library' },
    log_in: { url: 'assets/image/icons/toolbar/log-in.svg', name: 'ctb_log_in' },
    menu: { url: 'assets/image/icons/toolbar/menu.svg', name: 'ctb_menu' },
    save: { url: 'assets/image/icons/toolbar/save.svg', name: 'ctb_save' },
    settings: { url: 'assets/image/icons/toolbar/settings.svg', name: 'ctb_settings' },
    user_management: { url: 'assets/image/icons/toolbar/user-management.svg', name: 'ctb_user_management' },
    verification_done: { url: 'assets/image/icons/toolbar/verification-done.svg', name: 'ctb_verification_done' },
    verification_error: { url: 'assets/image/icons/toolbar/verification-error.svg', name: 'ctb_verification_error' },
    verification_failed_in_toolbar: {
        url: 'assets/image/icons/toolbar/verification-failed-in-toolbar.svg',
        name: 'ctb_verification_failed_in_toolbar',
    },
    verification_in_process: {
        url: 'assets/image/icons/toolbar/verification-in-process.svg',
        name: 'ctb_verification_in_process',
    },
    verification_in_toolbar: {
        url: 'assets/image/icons/toolbar/verification-in-toolbar.svg',
        name: 'ctb_verification_in_toolbar',
    },
};
