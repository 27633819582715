<ng-container *ngIf="!loading">
    <div class="wrapper">
        <div class="left">
            <div class="form">
                <div class="logo" style="background-image: url('assets/image/logo-horizontal.svg')"
                     (click)="goToPage('https://codiq.io/')"></div>
                <div class="form-wrapper">
                    <div class="greeting">
                        <div class="left-side" style="background-image: url('assets/image/Vector.svg')"></div>
                        <div class="greeting-text" style="background-image: url('assets/image/title.svg')"></div>
                        <div class="right-side" style="background-image: url('assets/image/Union.svg')"></div>
                    </div>

                    <form (submit)="submit()" [formGroup]="loginForm">
                        <div class="inner-wrapper">
                            <div class="field-wrapper">
                                <div class="field">
                                    <span class="label">Username</span>
                                    <div class="input">
                                        <i style="background-image: url('assets/image/icons/user.svg')"></i>
                                        <input formControlName="email" autocomplete="off"
                                               placeholder="Your email"
                                               [matAutocomplete]="auto">
                                    </div>
                                    <p class="error" *ngIf="email.errors?.required && email.touched">
                                        Email is required
                                    </p>
                                    <p class="error" *ngIf="email.errors?.email">
                                        Email is invalid
                                    </p>
                                </div>
                                <div class="field">
                                    <span class="label">Password</span>
                                    <div class="input">
                                        <i style="background-image: url('assets/image/icons/password.svg')"></i>
                                        <input type="password" formControlName="password" #passwordInput
                                               placeholder="Your password"
                                               autocomplete="off">
                                        <i *ngIf="passwordInput.type === 'text'" class="show"
                                           style="background-image: url('assets/image/show.svg')"
                                           (click)="showPassword(passwordInput)">
                                        </i>
                                        <i *ngIf=" passwordInput.type === 'password'" class="show"
                                           style="background-image: url('assets/image/unshow.svg')"
                                           (click)="showPassword(passwordInput)">
                                        </i>
                                    </div>
                                    <mat-error *ngIf="password.errors?.required && password.touched">
                                        Password is required
                                    </mat-error>

                                    <mat-error *ngIf="password.errors?.invalid">
                                        <i style="background-image: url('assets/image/icons/warning.svg')"></i>
                                        <div>
                                            Please check your username and password.
                                            <br/>
                                            If you still can't log in,
                                            <span class="support-link"
                                                  (click)="goToPage('https://codiq.io/contact/')">
                                                contact Support.
                                            </span>
                                        </div>
                                    </mat-error>
                                </div>
                            </div>

                            <div class="extra-info">
                                <div class="checkbox-label-wrapper">
                                    <input class="c1" formControlName="remember"
                                           (change)="setToRemember($event)"
                                           data-toggle="toggle"
                                           name="remember"
                                           type="checkbox">
                                    <span class="checkbox-label">Remember me</span>
                                </div>

                                <div class="password-link">
                                    <span class="text" (click)="goToForgotPassword()">Forgot password?</span>
                                </div>

                            </div>
                        </div>
                        <button class="button" type="submit" [disabled]="loginForm.invalid">
                         <span class="text">
                            Log In
                         </span>
                        </button>
                        <button class="button contact" (click)="goToPage('https://codiq.io/contact/')">
                            <span class="text support-link">New to CodiQ? Contact Us</span>
                        </button>
                    </form>
                </div>
            </div>
        </div>

        <div class="right" style="background-image: url('assets/image/bg.png')">
            <div class="i-frame">
                <iframe [src]="src" id="iframe_if" scrolling="no" frameborder="0" allowtransparency="true"></iframe>
            </div>
        </div>
        <div class="copyright">© 2022 CodiQ 4.0 Ltd. All rights reserved.</div>
    </div>
</ng-container>


<mat-autocomplete #auto="matAutocomplete"
                  (optionSelected)="setPassword($event.option.value)">
    <mat-option class="mat-line" *ngFor="let userEmail of filteredUsers | async" [value]="userEmail.email">
        {{userEmail.email}}
    </mat-option>
</mat-autocomplete>
