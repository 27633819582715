import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!window.navigator.onLine) {
            throwError(
                new HttpErrorResponse({
                    error: 'Service Unavailable. Could you check your Internet connection.',
                    status: 503,
                    url: null,
                })
            );
        }

        if (window.location.pathname.includes('login')) {
            sessionStorage.setItem('projectId', '');
        }
        const projectId = sessionStorage.getItem('projectId') ?? '';
        const sessionId = this.getSessionId();

        req = req.clone({
            headers: req.headers.set('projectId', projectId).set('sessionId', sessionId),
            withCredentials: true,
        });

        return next.handle(req);
    }

    private getSessionId(): string {
        let sessionId = sessionStorage.getItem('sessionId');
        if (sessionId == null) {
            sessionId = Math.random().toString(36).substr(2);
            sessionStorage.setItem('sessionId', sessionId);
        }

        return sessionId;
    }
}
