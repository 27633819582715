export const plcTypes = {
    Rockwell: 0,
    Siemens: 1,
};

export const drivers = {
    Rockwell: {
        Kepware: 0,
        'Rockwell Driver': 1,
    },
    Siemens: {
        Siemens: 0,
    },
};

export const cpuTypes = {
    rockwell: [
        { value: 0, name: 'ControlLogix' },
        { value: 1, name: 'Plc5' },
        { value: 2, name: 'Slc500' },
        { value: 3, name: 'LogixPccc' },
        { value: 4, name: 'Micro800' },
        { value: 5, name: 'MicroLogix' },
    ],
    siemens: [
        { value: 0, name: 'S7200' },
        { value: 10, name: 'S7300' },
        { value: 20, name: 'S7400' },
        { value: 30, name: 'S71200' },
        { value: 40, name: 'S71500' },
    ],
};

export const hmiTypes = [
    { value: 'Factory Talk V11', name: 'Factory Talk V11' },
    { value: 'Factory Talk V12', name: 'Factory Talk V12' },
    { value: 'WinCC RT Advanced', name: 'WinCC RT Advanced' },
    { value: 'WinCC RT Professional', name: 'WinCC RT Professional' },
];

export const libraryName = [
    { value: 'PlantPax V4.1', name: 'PlantPax V4.1' },
    { value: 'Basic Process Library V2.4', name: 'Basic Process Library V2.4' },
    { value: 'AST RO Panel Library V1', name: 'AST RO Panel Library V1' },
    { value: 'Food & Beverage Library', name: 'Food & Beverage Library' },
];

export const plcVersion = [
    // {value: 'V31', name: 'V31', type: 'Rockwell'},
    // changes for Malta project
    { value: 'V32.02', name: 'V32.02', type: 'Rockwell' },
    { value: 'V15', name: 'V15', type: 'Siemens' },
];

export const opcType = [{ value: 'Kepware', name: 'Kepware' }];

export const ethernetType = [
    { value: '1756-ENT2R', name: '1756-ENT2R' },
    { value: '1756-ENT2', name: '1756-ENT2' },
];

export const controllerType = [{ value: '1756-I73', name: '1756-I73' }];

export enum hmiVendors {
    'Rockwell',
    'Siemens',
    'Siemens TIA',
}

export const DATATYPE_NUMBER: string[] = ['INT', 'DINT', 'REAL'];
export const DATATYPE_BOOL: string = 'BOOL';
export const HIDE_IMPORT_AUTOCAD: boolean = false;

export const SUCCESS_TOASTER_OPTIONS = {
    timeOut: 1500,
    extendedTimeOut: 1500,
    closeButton: false,
};

export const WARNING_TOASTER_OPTIONS = {
    timeOut: 4000,
    extendedTimeOut: 1500,
    closeButton: true,
};
