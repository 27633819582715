<div  *ngIf="!loading">
    <h2 class="mat-headline">Registration</h2>

    <form (submit)="submit()" [formGroup]="form">
        <mat-form-field>
            <input matInput placeholder="Login" required formControlName="login" autofocus>
<!--            <mat-error *ngIf="name.errors?.required">-->
<!--                Name is required-->
<!--            </mat-error>-->
        </mat-form-field>
        <mat-form-field>
            <input matInput placeholder="First Name" required formControlName="firstName">
        </mat-form-field>
        <mat-form-field>
            <input matInput placeholder="Last Name" required formControlName="lastName">
        </mat-form-field>
        <mat-form-field>
            <input matInput placeholder="Email" required email formControlName="email">
<!--            <mat-error *ngIf="email.errors?.required">-->
<!--                Email is required-->
<!--            </mat-error>-->
<!--            <mat-error *ngIf="email.errors?.email">-->
<!--                Email is invalid-->
<!--            </mat-error>-->
<!--            <mat-error *ngIf="email.errors?.server">-->
<!--                {{ email.errors.server }}-->
<!--            </mat-error>-->
        </mat-form-field>
        <mat-form-field>
            <input matInput placeholder="Password" type="password" required formControlName="password">
<!--            <mat-error *ngIf="password.errors?.required">-->
<!--                Password is required-->
<!--            </mat-error>-->
        </mat-form-field>

        <button mat-flat-button type="submit" [disabled]="form.invalid">Send</button>
    </form>

    <a mat-button color="primary" [routerLink]="['/login']">I already have an account</a>
</div>
