<div class="wrapper">
    <div class="left">
        <div class="content-wrapper">
            <div class="logo" style="background-image: url('assets/image/logo-horizontal.svg')"></div>
            <div class="title">
                <div class="left-side" style="background-image: url('assets/image/Vector.svg')"></div>
                <div class="greeting-text" style="background-image: url('assets/image/reset_title.svg')"></div>
                <div class="right-side" style="background-image: url('assets/image/Union.svg')"></div>
            </div>
            <div class="content">
                <form (submit)="submit()" [formGroup]="resetForm">
                    <div class="inner-wrapper">
                        <div class="field-wrapper">
                            <div class="field">
                                <span class="label">Username</span>
                                <div class="input">
                                    <i style="background-image: url('assets/image/icons/user-disabled.svg')"></i>
                                    <input class="disabled" readonly type="text" formControlName="user">
                                </div>
                            </div>
                            <div class="field">
                                <span class="label">Password</span>
                                <div class="input">
                                    <i style="background-image: url('assets/image/icons/password.svg')"></i>
                                    <input placeholder="Enter a new password"
                                           formControlName="password"
                                           [readonly]="isReadOnly"
                                           (focusout)="removeAttribute(true)"
                                           (click)="setAttribute(false)"
                                           (input)="checkInput(passwordInput)"
                                           type="password"
                                           #passwordInput>
                                    <i *ngIf="passwordInput.type === 'text'" class="show"
                                       style="background-image: url('assets/image/show.svg')"
                                       (click)="showPassword(passwordInput)">
                                    </i>
                                    <i *ngIf=" passwordInput.type === 'password'" class="show"
                                       style="background-image: url('assets/image/unshow.svg')"
                                       (click)="showPassword(passwordInput)">
                                    </i>
                                </div>

                                <mat-error *ngIf="password.errors?.required && password.touched">
                                    Password is required
                                </mat-error>

                                    <mat-error *ngIf="password.errors?.passwordStrength && password.touched">
                                        <i style="background-image: url('assets/image/icons/warning.svg')"></i>
                                        <div>
                                            Your password should have minimum 8 chars,
                                            lower case, upper case and numeric characters
                                        </div>
                                    </mat-error>


                              <!--  <ng-container
                                    *ngIf="password.errors | onlyOneError:['minlength','passwordStrength'] as error">

                                    &lt;!&ndash;<div class="field-message" *ngIf="error.minlength">
                                        <i style="background-image: url('assets/image/icons/warning.svg')"></i>
                                        <div>
                                            Your password should have minimum {{error.minlength.requiredLength}} chars,
                                            <br/>
                                            but it only has {{error.minlength.actualLength}}.
                                        </div>

                                    </div>&ndash;&gt;

                                    <div class="field-message" *ngIf="error.passwordStrength && error.minlength">
                                        <i style="background-image: url('assets/image/icons/warning.svg')"></i>
                                        <div>
                                            Your password should have minimum 7 chars, lower case, upper case and numeric characters.
                                        </div>
                                    </div>

                                </ng-container>-->

                            </div>
                            <div class="field">
                                <span class="label">Confirm new password</span>
                                <div class="input">
                                    <i style="background-image: url('assets/image/icons/password.svg')"></i>
                                    <input placeholder="Confirm your new password"
                                           formControlName="confirm_password"
                                           [readonly]="isReadOnly"
                                           (focusout)="removeAttribute(true)"
                                           (click)="setAttribute(false)"
                                           (input)="checkInput(confirmInput)"
                                           type="password"
                                           #confirmInput>
                                    <i *ngIf="confirmInput.type == 'text'" class="show"
                                       style="background-image: url('assets/image/show.svg')"
                                       (click)="showPassword(confirmInput)">
                                    </i>
                                    <i *ngIf=" confirmInput.type == 'password'" class="show"
                                       style="background-image: url('assets/image/unshow.svg')"
                                       (click)="showPassword(confirmInput)">
                                    </i>
                                </div>

                                <mat-error *ngIf="confirm_password.errors?.required && confirm_password.touched">
                                    Password is required
                                </mat-error>

                                <mat-error *ngIf="confirm_password.errors?.passwordMatch">
                                    <i style="background-image: url('assets/image/icons/warning.svg')"></i>
                                    <div>Passwords do not match. Please, try again</div>
                                </mat-error>

                                <mat-error *ngIf="confirm_password.errors?.invalid">
                                    <i style="background-image: url('assets/image/icons/warning.svg')"></i>
                                    <div>Something went wrong. Please, try again later</div>
                                </mat-error>

                            </div>

                        </div>
                        <button class="button" type="submit" [disabled]="!resetForm.valid">
                         <span class="text">
                           Change password
                         </span>
                        </button>
                    </div>
                    <div class="back-navigation-wrapper">
                        <div class="back" (click)="goToLoginPage()">Back to <span>Login</span></div>
                    </div>
                </form>
            </div>
            <div class="copyright">© 2022 CodiQ 4.0 Ltd. All rights reserved.</div>
        </div>
    </div>
    <div class="right" style="background-image: url('assets/image/bg_forgot.png')"></div>
</div>


