import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from '@app/modules/core/services/user.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import {FormBuilder, NgModel, Validators} from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import {SUCCESS_TOASTER_OPTIONS} from "@app/modules/shared/code-settings/project-settings";
@Component({
    templateUrl: 'registration-page.component.html',
    styleUrls: ['registration-page.component.scss']
})

export class RegistrationPageComponent implements OnInit {
    @ViewChild('email') emailInput: NgModel;
    loading = false;
    form = this.fb.group({
        id: '',
        login: '',
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        roleId: 200,
    });

    constructor(
        private userService: UserService,
        private toastr: ToastrService,
        private spinner: NgxSpinnerService,
        private router: Router,
        private fb: FormBuilder
    ) { }

    ngOnInit() { }

    submit() {
        this.userService.register(this.form.value).subscribe({
            next: (data) => {
                this.spinner.show();
                this.loading = true;
                this.toastr.success('Registration successful, please log in using your credentials', '', SUCCESS_TOASTER_OPTIONS);
                this.router.navigate(['/login']).then(r => this.loading = false);
            },
            error: (err) => {
                this.toastr.error(err.message);
                this.emailInput.control.setErrors({
                    ...this.emailInput.control.errors,
                    server: err.message
                });
            }
        });
    }
}
